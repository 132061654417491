/* buton class characteristics*/
.SponsorButton{
    /* color:  white;
    font-size: calc(10px + 2vmin);
    font-family: 'Righteous', cursive;
    border-width: medium;
    border-radius: 20px;
    border-color: white;
    background-color: #e3b757;
    margin: auto;
    margin-right: 10px;
    margin-left: 10px;
    margin: 1rem auto; */
    min-width: fit-content;
    width: fit-content;
    color: #48b4bd !important;
    background-color: white !important;
    border-width: medium !important;
    border-radius: 20px !important;
    border-color: #48b4bd !important;
    margin: 15px;
}

.div-for-sponsor-buttons {
    display:inline-block;
    align-items: center;
}
  
.home-sponsor-image {
    min-height: 3vh;
    min-width: 6vh;
    width: 44vh;
}
  
/*buton class when selected*/
.SponsorButton:hover{
    background-color: #b4e9ed
}

/*link style*/

a.SponsorButton {
    color:	#276268;
    font-family: 'Righteous', cursive;
    text-decoration: none;
}
/*selected link*/
a.SponsorButton:active {
    color: black;
}
/*visited link*/
a.SponsorButton:visited {
    color:#48B4BD;
}


