.tutorial-pics{
    height: 15rem;
    width: 30rem;
}

.verify{
    font-size: 2rem;
    padding-top: 3rem;
}

.Check{
    font-size: 30px;
    text-align: center;
    line-height: 50px;
    width: 50px;
    height: 50px;
}

.dot{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #48B4BD;
    display: inline-block;
    margin: 0 1rem;
}


.tinyBody{
    padding: 4rem;
}



#false{
    background-color: #e37158;
}

.numberDot{
    background-color: #e37158;
}

.numbers{

    text-align:center;
    line-height:50px;
    width:50px;
    height:50px;
    font-size:1.5rem;
}

.numberCard{
    padding:1rem!important;
    border-color: #48B4BD;
    border-width: .25em;
    border-radius: 0.5em;
    border-style: solid;
    margin: 1em;
}