.verify-email {
    padding-top: 1rem;
}

.profile-img-large {
    width: 5rem;
    border-radius: 10000px;
}

div.profile-container {
    align-self: center;
    text-align: center;
    padding: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
    background-color: rgba(0,0,0,0.1);
    
}

.profile-data-header {
    font-size: 1.3rem;
    margin-bottom: 0.3rem;
}

.display-name {
    font-size: 1.2rem;
}

.email-address {
    font-size: 0.8rem;
}

.profile-data-card-large {
    margin: 0.3rem;
    min-width: 500px; 
    /* max-width: 500px */
}

.profilepage-maincontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
.profile-data-card {
    display: flex;
    align-content: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    background-color: white;
    border-radius: 5px;
    margin: 5px;
    padding: 10px;
    height: 100%;
    flex-direction: column;
    /* max-width: 570px; */
    /* max-width: 60vw */
}

.profile-performance-card {
    display: flex;
    justify-content: center;
    width: 1200px;
    max-width: 1200px;
}

.profile-small-card-container {
    display: flex;
    justify-content: center;
    align-content: space-evenly;
}

.profile-data-card-small {
    width: 100%;
}
.padding {
    margin: 0.3rem;
    /* min-width: 0px;
    max-width: 50px; */
}


.profile-left-column {
    /* Create two equal columns that floats next to each other */
    display: grid;
    max-width: 600px;
    align-items: center;
    text-align: center !important;
  }

  .profile-right-column {
    /* Create two equal columns that floats next to each other */
    display: grid;
    max-width: 600px;
    align-items: center;
    text-align: center !important;
  }
  
  .profile-row {
    content: "";
    display: flex;
    clear: both;
    align-items: center;
    background-color: white;
    flex-wrap:wrap;
    justify-content: center;
  }

  /* .profile-row.big-screen.profile-data-card{
    max-width: 1180px;
} */



  .pie-data-card {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

/*Big screens*/
/* @media screen and (min-width: 1200px) {
    .profile-data-card {
        max-width: 1180px;
    }

  } */