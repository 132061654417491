img.profile-img {
    padding: 5px;
    height: 2.5rem;
    border-radius: 10000px;
    align-self: center;
    text-align: center;
}

div#profile-img-div {
    align-items: center;
}

img.profile-img:hover {
    background-color: rgba(0,0,0,0.1);
    transition: background 250ms ease-in;
}