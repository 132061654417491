/* HomeCard properties */

.sponsor-row {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.card-background {
    background: #FFFFFF;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    min-height: 30vh;
    height: fit-content;
    width: 48%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 10px;
    /* margin-right: 50px; */
}

.image-background-bci, .image-background-branch-out, .image-background-uofa {
    position: relative;
    height: auto;
    width: 50vw;
    left: 0px;
    top: 0px;
    bottom: 0px;
    background: #BCD1FF;
    border-radius: 16px 0px 0px 16px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: unset;
}

.image-background-bci {
    background-image: url("/src/open-bci-product.png");
}

.image-background-branch-out{
    background-image: url("/src/branchout_pic.png");
  }

.image-background-uofa {
    background-image: url("/src/uofa-image.png");  
}


.container-text {
    padding: 20px;
    text-align: left;
}
.container-button {
    padding: 20px;
    text-align: center;
}
.sponsor-title {
    position: relative;
    font-family: 'Righteous', cursive;
    font-size: 18pt;
    font-weight: 100;
    line-height: 27px;
    /* Additional text */
    color: #6E798C;
}
.sponsor-p {
    position: relative;
    top: 10%;
    width: 100%;
    /* Open Sans / 18 sp • Body 1 */
    font-family: 'Roboto', sans-serif;
    font-size: 12pt;
    font-weight: 500;
    line-height: 27px;
    /* or 150% */
    /* Body text */
    color: #374A59;
    text-align: left;
    margin-bottom: 40px;
}

@media only screen and (max-width: 1200px) {
    .image-background-bci, .image-background-branch-out, .image-background-uofa {
    display: none;
  }
}