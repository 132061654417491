
/* // BUTTON PROPERTIES // */
.footer {
    left: 0;
    right: 0;
    /* bottom: 0; */
    width: 100%;
    text-align: center;
    position: absolute;
    color: white;
    overflow: hidden;
    display: inline;
    z-index: 999;
}

/* social media button class */
.social-media-button {
    padding: 10px;
    border-radius: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 40%;
    max-height: 40%;
}
/* social media button specific shape*/
.social-media-button path {
    fill: #48b3bd;
    max-height: 50px;
}

/* clicked social media button */
.social-media-button path:active {
    fill: white;
    max-height: 50px;
}

/* selected social media button*/
.social-media-button:hover {
    border-radius: 50%;
    background-color: #9cd1d5;
    transition: 250ms ease-in;
} 

/* clicked social media button */
.social-media-button:active {
    background-color: #9cd1d5;
}

/* // FOOTER PROPERTIES//  */

.footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    padding-top: 10px;
}

.text {
    margin-left: 10px;
    color: #276268;
}

.icons-container {
    display: flex;
    align-items: center;
}
.footer-line {
    background-color: #48b3bd;
    position: relative;
    left: 0;
    bottom: 0;
    height: 15px;
    width: 100%;
    z-index:1000;
    margin-top: 10vw;
}

/*Samll screens...*/
@media screen and (max-width: 1000px) {
    .footer {
        position: fixed;
        bottom: 0;
    }
}