.communityBody{
    background-image: url("/src/background_top.png");
    background-size: 100%;
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
}

h1{
    margin-top: 5%;
    margin-bottom: 5px;
    text-align: center;
}

.subtitle{
    font-size: 20px;
    padding-bottom: 5px;
}

.marginRight5{
    margin-right: 5px;
}

.marginBottom24{
    margin-bottom: 24px;
}

.paddingBottom5{
    padding-bottom: 5px;
}

.leaderboardCards{
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 768px){
    .leaderboardCards{
        min-width: 370px;
        width: 90%;
    }
    .profile-pic{
        margin-right: 10px;
        margin-left: 5px;
    }
    .cards-content{
        padding: 20px 10px;
    }
    .detail-stats{
        padding: 0 10px;
    }
    h1{
        font-size: 1.7em;
    }
}

@media (min-width: 768px){
    .leaderboardCards{
        width: 720px;
    }
    .profile-pic{
        margin-right: 25px;
        margin-left: 15px;
    }
    .cards-content{
        padding: 20px 25px;
    }
    .detail-stats{
        padding: 0 25px;
    }
    h1{
        font-size: 2em;
    }
}

@media(max-width: 450px){
    .viewDetails{
        display: none;
    }
}

.viewDetailsButton{
    color: black;
}

.viewDetailsButton:hover{
    text-decoration: underline;
    cursor:pointer;
}

.cards{
    margin-bottom: 5px;
}

.divider{
    height: 2px;
    background-color: black;
    margin-bottom: 5px;
}

.profile-card, .stats-card{
    height: auto;
    background-color: rgb(248, 248, 248);
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px 0 rgba(0,0, 0, 0.1), 0 1px 2px 0 rgba(0,0,0, 0.06);
}

.stats-card{
    margin-top: 10px;
    margin-bottom: 15px;
}

.cards:first-child .profile-card{
    background-color: #48b4bd;
}

.cards:nth-child(2) .profile-card{
    background-color: #E37158;
}

.cards:nth-child(3) .profile-card{
    background-color: #e3b757;
}

.rankingNum{
    font-size: 50px;
    min-width: 65px;
}

.profile-pic{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.profile-name{
    font-size: 28px;
    margin-bottom: 2px;
}

.sortedByButton{
    cursor: pointer;
}