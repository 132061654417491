a {
    color:#e37158;
}
.header-sizer {
    height: 80px;
    /* overflow: hidden; */
}

.header {
    background-color: #48b4bd;
    position: fixed;
    vertical-align: middle;
    display: inline-block;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    justify-content: space-between;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
}

.drop-shadow {
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}

.floating {
    position:fixed;
    top:0;
    z-index: 100;
}

.navBarLink {
    /*The color was overriden by the imported component*/
    /*hey let's avoid using !important, it tends to overlook other problems in the code,
    if you run into these kinds of problems, try fixing how specific the code is and it should work better
    that is, use multiple classes as target so that the css doesn't spread to other pages
    e.g. instead of .a maybe do .a.buttonPretty etc. this is targetting a very specific tag not all <a> tags*/
    color: white;
    display:inline-block;
    margin:0;
    text-transform:uppercase;
    font-size: 20px;
    margin-right: 1.5rem;
    text-decoration: none;
    padding-bottom: 5px;
}
.navBarLink:after {
    display:block;
    content: '';
    border-bottom: solid 3px white;
    transform: scaleX(0);
    transition: 250ms ease-in-out;
}
.navBarLink:hover:after {
    transform: scaleX(1);
}

.navBarLink:active:after {
    color: #e3b757;
    border-bottom: none;
}
.navBarLink:active {
    color: #e3b757;
    border-bottom: solid 3px #e3b757;
    transform: none;
}
.unfancy-link.home-link {
    text-decoration: none!important;
}

.unfancy-link.home-link:after {
    display:block;
    content: '';
    border-bottom: solid 3px black;
    transform: scaleX(0);
    transition: 250ms ease-in-out;
}
.unfancy-link.home-link:hover:after {
    transform: scaleX(1);
}

.unfancy-link.home-link:active:after {
    color: white;
    border-bottom: none;
}
.unfancy-link.home-link:active {
    color: white;
    border-bottom: solid 3px white;
    transform: none;
}

 @media only screen and (max-width: 500px) {
    .logo {
        display: none;
    }
 }