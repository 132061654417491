/* buton class characteristics*/
.LogInButton{
    color:  white;
    font-size: calc(10px + 2vmin);
    font-family: 'Righteous', cursive;
    border-width: medium;
    border-radius: 20px;
    border-color: white;
    background-color: #e3b757;
    margin: auto;
    margin-right: 10px;
    margin-left: 10px;
}
/*buton class when selected*/
.LogInButton:hover{
    background-color: #f3daa5;
}

/*link style*/

a.LogInButton {
    color:	white;
    font-family: 'Righteous', cursive;
    text-decoration: none;
}
/*selected link*/
a.LogInButton:active {
    color: black;
}
/*visited link*/
/* a.LogInButton:visited {
    color:#a5e6a7;
} */


