@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

h1 {
  font-family: 'Righteous', cursive;
  font-size: 6vh;
  margin-bottom: 0px;
}

h2 {
  font-family: 'Righteous', cursive;
  font-size: 4vh;
  margin-bottom: 0px;
}

p {
  font-family: 'Roboto', sans-serif;
  font-size: 3vh;
  font-weight: 200;
  text-align: center;
}

a{
  text-decoration: none;
  font-weight: 400;
  color: #E78974;
}

ul{
  list-style-type: none;
  padding: 1vh;
  margin:0;
}


li{
  padding: 0.2rem 0;
}


em {
  color: rgb(70, 70, 70);
  font-weight: 600;
  font-style: inherit;
}

hr {
   /*background: linear-gradient(to left, #48b4bd, white);*/
  height: 2px;
  border-width: 0;
}

.Info {
  padding-top: 65px;
  width: 100%;
  text-align: center;
  background-color: white;
  background-image: url('/src/background_top.png');
  background-size: 100%;
  background-repeat: no-repeat;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 80px 60px 100px 60px;
  gap: 150px;
  
}

.mainContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 70px;
}

.cardMedia {
  margin: 0;
  max-width: 650px;
  max-height: 366px;
  background-color: transparent;
}

.steamCard {
  color: white !important;
  background-color: #48b4bd !important;
  width: 100%;
}

.steamText {
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 3vh;
  font-weight: 200;
}

.steamTitle {
  font-family: 'Righteous', cursive;
  font-size: 5vh;
}

.steamRequirements {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.steamTinyHeading {
  font-family: 'Righteous', cursive;
  font-size: 3vh;
  padding: 2vh 0 2vh 0;
  text-decoration: underline;
}



@media only screen and (max-width: 1100px) {
  .mainContent {
    flex-direction: column;
  }

  .cardMedia {
    max-width: 560px;
    height: 315px;
  }

  .steamRequirements {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 700px) {
  .content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .cardMedia {
    max-width: 400px;
    height: 225px;
  }
}

@media only screen and (max-width: 370px) {
  .steamText {
    font-size: 2vh;
  }

  .steamTitle {
    font-size: 2.8vh;
  }

  .steamTinyHeading {
    font-size: 2.5vh;
  }
}
