@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  overflow-x: hidden;
}

/*this div allows for the scrolling,
it holds the containers to be scrolled thru*/
.parallax {
  /* Set a specific height */
  width: 100%;
  margin-top: -85px;
  display: inline-block;
  position: relative;

  /* Create the parallax scrolling effect */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: fixed;
  background-color: white;
  left: 0;
  right: 0;
  text-align: center;
  overflow: hidden;
}

/*FONTS FOR THE HOME PAGE*/

.home2-h1 {
  font-family: 'Righteous', cursive;
  font-size: 46pt;
  font-weight: 900;
}

.home2-h2 {
  font-family: 'Righteous', cursive;
  font-size: 18pt;
  font-weight: 200;
  text-align: center;
  width: 100%;
}

.home2-h3 {
  font-family: 'Righteous', cursive;
  font-size: 18pt;
  font-weight: 100;
}

.home2-h4 {
  font-family: 'Roboto', sans-serif;
  font-size: 12pt;
  font-weight: 500;
}

/*allows for the page to wrap*/
.flex-columns {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

/*FIRST COONTAINER*/
/*holds background and title*/
.home2-mainContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/*BACKGROUND WITH LOGO*/
.background-image {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  flex: 1 1 0%;
  width: 40vw;
  min-height: 40vw;
  background-image: url("/src/test_background.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: local;
  background-color: white;
  padding: 7vw 1vw 0vw 1vw;
  margin-right: 60px;
  margin-top: 15px;
}

/*main title and login*/
.title-container {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  padding-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 1 0%;
  width: 70%;
  background-color: white;
  padding: 80px 60px 30px 60px;
  margin-right: -100px;
  margin-left: 0;
}

.bullet-points {
  margin-top: 50px;
  width: 100%;
}

/*SECOND COONTAINER*/
/*holds steps for using koalacademy*/
.steps-container {
  min-height: 10%;
  width: 100vw;
  background-color: #b4e2e6;
  font-size: 36px;
  top: 1000px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/*THIRD COONTAINER*/
/*info container holds two sponsors containers*/

.info-container {
  height: fit-content;
  width: 100vw;
  background-color: white;
  font-size: 36px;
  top: 1000px;
  align-content: center;
  text-align: center;
  border-left-width: 10px;
  border-left-style: solid white;
  border-right-style: solid white;
  border-right-width: 10px;
}

.bci-info {
  align-items: center;
  padding-top: 1.5vh;
  padding-bottom: 2vh;
  background-color: rgb(190, 190, 190);
  width: 100%;
  height: fit-content;
}

.sponsors-info {
  padding-top: 1.5vh;
  padding-bottom: 2vh;
  background-color: white;
  width: 90%;
  height: fit-content;
  padding-left: 4vh;
  padding-right: 4vh;
  text-align: center;
}

.sponsorButtons {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.bci-column {
  /* Create two equal columns that floats next to each other */
  display: grid;
  width: 50%;
  align-items: center;
  text-align: center !important;
}

.bci-column.bci-text {
  background-color: white;
  padding-right: 15px;
  padding-left: 15px;
  width: fit-content;
  height: fit-content;
  min-height: 20vw;
}

.bci-row {
  content: "";
  display: flex;
  clear: both;
  align-items: center;
  background-color: white;
}

.open-bci-product, .branch-out-image, .jacqueline-image{
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  min-width: 30vh;
  min-height: 20vw;
  background-image: url("/src/open-bci-product.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: unset;
  background-color: white;

}

.branch-out-image{
  background-image: url("/src/branchout_pic.png");
}

.jacqueline-image{
  background-image: url("/src/jcummine.jpg");
}



/*FOURTH COONTAINER*/
/*another option for where to put sponsors*/

.sponsors-container {
  height: fit-content;
  background-color: rgb(235, 235, 235);
  font-size: 36px;
  top: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

/*FIFTH COONTAINER*/
/*will most likely contain information OR picture about the unity game,
and baseline*/

.video-container {
    height: 100vw;
    background-color: blue;
    font-size: 36px;
    top: 1000px;
}

/*FIFTH COONTAINER*/

.notes-on-game-container {
    height: 100vw;
    background-color: black;
    font-size: 36px;
    top: 2000px;
}

/*below are the bacground elements, each hold a png
that is place in absolute position*/

.neuron {
  position: absolute;
  width: 174px;
  height: 373px;
  left: 3%;
  top: 15%;
  background-image: url("/src/neuron.png");
  background-attachment: local;
  background-size: contain;
  z-index: 1;
}

.neuron2 {
  position: absolute;
  width: 269px;
  height: 581px;
  left: 6%;
  top: 60%;
  background-image: url("/src/neuron.png");
  background-attachment: local;
  background-size: contain;
  z-index: 1;
  transform: rotate(-45deg);
  display:none;
}


.tiles {
  position: absolute;
  width: 306px;
  height: 167px;
  left: 45%;
  top: 12%;
  background-image: url("/src/cards.png");
  background-attachment: local;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
}

.foldercard {
  position: absolute;
  width: 207px;
  height: 185px;
  left: 85%;
  top: 67%;
  background-image: url("/src/card-folder.png");
  background-attachment: local;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
}

/*the media queries below maintain legibility for large and small screen sizes*/

@media only screen and (max-width: 1100px) {
  .home2-mainContent {
    flex-direction: column;
  }
}

@media only screen and (max-width: 900px) {
  .neuron {
    left: 1%;
    top: 15%;
    width: 131px;
    height: 281px;;
  }
}

@media only screen and (max-width: 1300px) {
  .neuron2 {
    display: none;
  }
}

@media only screen and (min-width: 1100px) {
  .neuron {
    width: 269px;
    height: 581px;
    left: calc(30% - 520px);
    top: 25%;
  }
  .tiles {
    width: 385px;
    height: 210px;
  }
  .foldercard {
    width: 260px;
    height: 232px
  }
  .home2-h4 {
    font-size: 16pt;
  }
}

@media only screen and (max-width: 1100px) {
  .tiles {
    left: 10%;
    top: 60%;
  }
  .foldercard {
    left: 70%;
    top: 75%;
  }
  .background-image {
    background-image: url("/src/test_background2.png");
    width: 100vw;
  }
  .bullet-points {
    display: none;
  }
  .title-container {
    padding: 80px 160px 30px 60px;
  }

}
@media only screen and (max-width: 900px) {
  .tiles {
    left: 10%;
    top: 60%;
    width: 211px;
    height: 115px;
  }
  .foldercard {
    left: 70%;
    top: 80%;
    width: 211px;
    height: 115px;
  }
  .home2-h1 {
    font-size: 30pt;
  }
  .fancy-digit {
    font-size: 25px;
  } 
  .title-container {
    padding: 80px 40px 30px;
    margin-right: 0px;
    padding-top: 10%;
  }
  .bci-row {
    justify-content: center;
  }
  .bci-column {
    min-height: 40vw;
  }
  .open-bci-product {
    min-height: 60vw;
  }
  .SponsorButton {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  
}

@media only screen and (max-width: 400px) {
  /* .title-container {
    padding: 0 0 0 0;
    margin-right: 0px;
    padding-top: 30px;
  } */
  .tiles {
    display: none;
  }
  .Cards {
    padding: 0;
  }
  .neuron {
    display: none;
  }
  .title-container {
    padding-top: 20%;
    padding-right: 20%;
    width: fit-content;
  }
  .bci-column.bci-image {
    display: none;
  }
}

/*below are other componenent that contribute to the overall
layout outlined in the css above*/

.learn-more-link {
  color: #48B4BD;
  font-size: 2vh;
}

.bullet {
  max-height: 1em;
  max-width: 1em;
  padding-right: 0.5em;
  margin-bottom: -0.3em;
}

.fancy-digit{
  height: 2em;
  width: 2em;
  border-radius: 50%;
  color: white;
  vertical-align: middle;
  display: table-cell;
  background-color: #48B4BD;
  border: 5px solid white;
}

.character {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 300px;
  width: 300px;
  background-color: #e37158;
  border-radius: 20px;
}

.home-card {
  height: 7em;
  width: 30vw;
  color: #48B4BD;
  background-color: white;
  border: solid #48B4BD;
  border-radius: 20px;
  margin: 0.5vw;
  /* margin-bottom: 1vw; */
}

.card-container {
  display: inline-block;
}

.home-stats-card {
  font-size: 1vw !important;
  margin: 1rem auto;
  height: 12vh;
  width: 20vw;
  min-width: 100%;
  color: #48b4bd !important;
  box-shadow: none !important;
}

.home-stats-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3vw;
}

.home-stats-text {
  font-family: 'Righteous', cursive;
  font-size: 3vh;
  font-weight: 200;
}

.home-stats-number {
  color: #000000;
  font-size: xx-large;
}


/*Big screens*/
@media screen and (min-width:1280px)  {
  .cardGrid{
    display: inline-block;
  }
}

/*Small screens*/

@media screen and (max-width:900px){
.cardGrid {
  margin: 0 auto !important;
  }
}

/*Big screens*/
@media screen and (min-width: 1280px) {
  .cardGrid {
    display: inline-block;
  }
  .home-card {
    margin: 1rem;
  }
  .home-stats-card {
    margin: 1rem;
  }

}

/* Medium Screens */
@media screen and (max-width: 900px) {
  .home-stats-card {
    width: 50vw;
  }
}

/*Small screens*/
@media screen and (max-width: 500px) {
  .home-stats-card {
    height: 100%;
  }

  .home-stats-flex {
    flex-direction: column;
  }

}
