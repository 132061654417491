html, body {
    max-width: 100%;
    /* overflow-x: hidden; */
    /* background-image: url("/src/background_bot.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: bottom; */
    background-image: url("/src/background_top.png");
    background-size: 100%;
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
}
#team-page {
    min-height: 100vh;
}

.title {
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5px;
}

@media (min-width: 768px) {
    .team-container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .team-member {
        padding: 1rem;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        min-width: 250px;
        max-width: 250px;
    }

    .member-position{
        font-size: 1rem;
    }

    .member-name{
        font-size: 21px;
    }

    .meet{
        font-size: 2em;
    }

    .dev-mention {
        font-size: 20px;
    }

}

@media (max-width: 768px) {
    .meet{
        font-size: 1.7em;
    }

    .dev-mention {
        font-size: 18px;
    }

    .team-member {
        padding: 0.5rem;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        min-width: 200px;
        max-width: 200px;
    }

    .member-name{
        font-size: 17px;
    }

    .member-position{
        font-size: 14px;
    }
}

.team-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.team-member {
    display: flex;
    flex: 2 1 auto;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
}

.team-member:hover {
    background-color: rgb(240, 240, 240);
    cursor: pointer;
}

.active {
    background-color: rgb(240, 240, 240);
}

.member-photo {
    width: 9.5rem;
    height: 9.5rem;
    border-radius: 50%;
    object-fit: cover;
}

.member-name {
    margin-top: 8px;
    margin-bottom: 5px;
}

.member-info-expanded {
    width: 60%;
    height: fit-content;
    background-color: rgb(240, 240, 240);
    position: absolute;
    display: flex;
    box-sizing: border-box;
    padding: 2%;
    margin-left: 3%;
    margin-right: 3%;
    border-radius: 5px;
    box-shadow: rgb(190, 190, 190) 0px 2px 7px 0px;
    overflow: hidden;
    min-width: 90vw;
}

.hidden {
    position: absolute;
    left: -9999px;
    visibility: hidden;
}

.personal-info {
    margin-top: -0.5%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.linkedin-logo {
    margin-right: 5px;
}

.linkedin-link {
    padding: 1% 2% 1% 2%;
    margin-top: 1rem;
    width: min-content;
    border-radius: 5px;
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
}

.linkedin-link:hover {
    background-color: #e4e4e4;
    cursor: pointer;
}

.member-name-expanded {
    font-size: 1.5rem;
    width: max-content;
}

@media only screen and (max-width: 600px) {
    .member-info-expanded {
        flex-direction: column;
        width: max-content;
        max-width: 90%;
    }

    .linkedin-link {
        margin-top: 3px;
        padding: 0;
    }

    .personal-info {
        margin-bottom: 20px;
    }
}

/* Mobile member card */
.mobile-member-card{
    margin: 15px;
}

.mobile-member-photo{
    width: 8.5rem;
    height: 8.5rem;
    border-radius: 50%;
    object-fit: cover;
}