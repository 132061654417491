@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

/* .Cards{
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  padding: 2vw;
  position: relative;
  width: 70%;
  padding-left: 15%; 
  padding-right: 15%;
} */
.card-container {
  display: inline-block;
}

.home-stats-card {
  font-size: 1vw !important;
  margin: 1rem auto;
  height: 12vh;
  width: 20vw;
  min-width: 100%;
  color: #48b4bd !important;
  box-shadow: none !important;
}

.home-stats-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-stats-text {
  font-family: 'Righteous', cursive;
  font-size: 3vh;
  font-weight: 200;
}

.home-stats-number {
  color: #000000;
  font-size: xx-large;
}

.home-sponsor-card {
  margin: 1rem auto;
  min-width: 90%;
  width: 30vw;
  color: #48b4bd !important;
  background-color: white !important;
  border-width: medium !important;
  border-radius: 20px !important;
  border-color: #48b4bd !important;
}

.home-sponsor-image {
  min-height: 10vh;
  min-width: 90%;
}
  /* .cardGrid{
    display: inline-block;
  } */


@media screen and (min-width:1280px)  {
  .cardGrid{
    display: inline-block;
  }
}


@media screen and (max-width:900px){
.cardGrid {
  margin: 0 auto !important;
  }
}

@media screen and (min-width: 1280px) {
  .cardGrid {
    display: inline-block;
  }
  /* .home-stats-card {
    margin: 1rem;
  }
  .home-sponsor-card {
    margin: 1rem;
  } */
}


@media screen and (max-width: 900px) {
  .home-stats-card {
    width: 50vw;
  }
}


@media screen and (max-width: 500px) {
  .home-stats-card {
    height: 100%;
  }

  .home-stats-flex {
    flex-direction: column;
  }
}


@media screen and (max-width: 300px) {
  .home-stats-flex {
    flex-direction: column;
  }
}