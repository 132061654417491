.error {
    margin: auto;
    margin-top: 100px;
    text-align: center;
    width: fit-content;
    padding: 5px 30px 20px 30px;
    background-color: rgb(233, 111, 111);
    border-radius: 20px;

    font-size: larger;
}

.error h1 {
    font-size: 3rem;
}