@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

.App {
  width: 100%;
  font-family: 'Righteous', cursive;
  overflow-y:auto;
  overflow:initial;
}

button {
  outline: none;
  border-radius: 20px;
  padding: 5px;
  background-color: transparent;
  border-width: 1.5px;
  border-color: black;
}

button:active {
  background-color: rgba(0, 0, 0, 0.05);
}

.brandName {
  font-size: 35px;
  margin-top: 1rem;
}

.headerTitle {
  font-size: 35px;
  margin-top: 1rem;
}
.unfancy-link {
  font-size: 32px;
  margin-top: 0;
  margin-right: 1rem;
  text-decoration: none;
  color: black;
}
.unfancy-link:hover {
  text-decoration: underline;
}

.logo {
  height: 4rem;
}

.spacer {
  width: 0.5rem;
  display: inline-block;
}

.coin {
  height: 2.5rem;
}

.coinCount {
  font-size: 30px;
}

.burger {
  /* position: fixed;
  right: 14px;
  top: 14px; */
  /* z-index: 100000; */
  display: none;
}

.burgerPart {
  background-color: #FFFFFF;
  position: relative;
  width: 39px;
  height: 10px;
  border-radius: 5px;
  margin: 4.5px;
  margin-right: 10px;
  margin-left: 10px;
  transition: all 0.25s ease-in-out;
}

.burger:active .burgerPart {
  background-color: rgba(160,160,160,1);
}

@media screen and (max-width: 900px) {
  .headerLink {
    display: none;
  }

  .burger {
    display: inline;
  }
}


@media screen and (max-width: 450px) {
  .brandName {
    display: none;
  }

  .character {
    height: 150px;
    width: 150px;
  }

  .Button {
    width: 20vw;
    height: 40px;
    border-radius: 10px;
    font-size: 15px;
  }
}
