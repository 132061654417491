.hint {
  color: rgba(0,0,0,0.6);
  font-size: 0.8rem;
}
.session-details {
  background-color: rgba(0,0,0,0.05);
  border-radius: 20px;
  padding: 10px;
}
.mediumcard {
  min-width: 500px;
  min-height: 200px;
}

.characterSmall {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height:50px;
  min-width: 30px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5px !important;
  background-color: #E37158;
  border-radius: 20px;
  

}
.characterMedium {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height:150px;
  width: 150px;
  background-color: #e3b758;
  border-radius: 20px;
  font-family: 'Righteous', cursive;
  margin: 10px 5px 0 0 !important;
}

/*This changed the pie chart text to black
why? idk ask google*/
g text
{
    fill: black;
}

g path
{
  stroke-width: 5;
}

@media only screen and (max-width: 600px) {
  .characterMedium {
    height:120px;
    width: 120px;
  }
}