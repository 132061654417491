#logo {
    height: 70%;
}
/* 
#circleProgress {
    height: 10rem;
} */

#loadingText {
    font-family: 'Righteous', cursive;
}