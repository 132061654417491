.title-container {
  display: flex;
  flex-direction: column;
 align-items:center;
  margin: 5%;
}

.info-title {
  font-size: 3rem;
}

.buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

.button {
  background-color: rgba(255,255,255,0);
  color: black;
  width: 20rem;
  height: 4rem;
  font-size: 1.5rem;
  transition: transform 0.2s;
  border-radius: 20px;
  border: solid;
  font-family: 'Righteous', cursive;
}
.button:hover {
  background-color: rgba(0,0,0,0.2);
  transform: scale(1.05)
}
.button:active {
  backround-color: red;
  transform: scale(1)
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

.redSquare {
  height: 100px;
  width: 100px;
  background-color: red;
}

.greenSquare {
  height: 100px;
  width: 100px;
  background-color: green;
}