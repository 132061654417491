@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 100%;
  font-family: 'Righteous', cursive;
  overflow-y:auto;
  overflow:visible;
  overflow:initial;
}

button {
  outline: none;
  border-radius: 20px;
  padding: 5px;
  background-color: transparent;
  border-width: 1.5px;
  border-color: black;
}

button:active {
  background-color: rgba(0, 0, 0, 0.05);
}

.brandName {
  font-size: 35px;
  margin-top: 1rem;
}

.headerTitle {
  font-size: 35px;
  margin-top: 1rem;
}
.unfancy-link {
  font-size: 32px;
  margin-top: 0;
  margin-right: 1rem;
  text-decoration: none;
  color: black;
}
.unfancy-link:hover {
  text-decoration: underline;
}

.logo {
  height: 4rem;
}

.spacer {
  width: 0.5rem;
  display: inline-block;
}

.coin {
  height: 2.5rem;
}

.coinCount {
  font-size: 30px;
}

.burger {
  /* position: fixed;
  right: 14px;
  top: 14px; */
  /* z-index: 100000; */
  display: none;
}

.burgerPart {
  background-color: #FFFFFF;
  position: relative;
  width: 39px;
  height: 10px;
  border-radius: 5px;
  margin: 4.5px;
  margin-right: 10px;
  margin-left: 10px;
  transition: all 0.25s ease-in-out;
}

.burger:active .burgerPart {
  background-color: rgba(160,160,160,1);
}

@media screen and (max-width: 900px) {
  .headerLink {
    display: none;
  }

  .burger {
    display: inline;
  }
}


@media screen and (max-width: 450px) {
  .brandName {
    display: none;
  }

  .character {
    height: 150px;
    width: 150px;
  }

  .Button {
    width: 20vw;
    height: 40px;
    border-radius: 10px;
    font-size: 15px;
  }
}

body {
  overflow-x: hidden;
}

/*this div allows for the scrolling,
it holds the containers to be scrolled thru*/
.parallax {
  /* Set a specific height */
  width: 100%;
  margin-top: -85px;
  display: inline-block;
  position: relative;

  /* Create the parallax scrolling effect */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: fixed;
  background-color: white;
  left: 0;
  right: 0;
  text-align: center;
  overflow: hidden;
}

/*FONTS FOR THE HOME PAGE*/

.home2-h1 {
  font-family: 'Righteous', cursive;
  font-size: 46pt;
  font-weight: 900;
}

.home2-h2 {
  font-family: 'Righteous', cursive;
  font-size: 18pt;
  font-weight: 200;
  text-align: center;
  width: 100%;
}

.home2-h3 {
  font-family: 'Righteous', cursive;
  font-size: 18pt;
  font-weight: 100;
}

.home2-h4 {
  font-family: 'Roboto', sans-serif;
  font-size: 12pt;
  font-weight: 500;
}

/*allows for the page to wrap*/
.flex-columns {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

/*FIRST COONTAINER*/
/*holds background and title*/
.home2-mainContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/*BACKGROUND WITH LOGO*/
.background-image {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  flex: 1 1;
  width: 40vw;
  min-height: 40vw;
  background-image: url(/static/media/test_background.41c0c9df.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: local;
  background-color: white;
  padding: 7vw 1vw 0vw 1vw;
  margin-right: 60px;
  margin-top: 15px;
}

/*main title and login*/
.title-container {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  padding-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 1;
  width: 70%;
  background-color: white;
  padding: 80px 60px 30px 60px;
  margin-right: -100px;
  margin-left: 0;
}

.bullet-points {
  margin-top: 50px;
  width: 100%;
}

/*SECOND COONTAINER*/
/*holds steps for using koalacademy*/
.steps-container {
  min-height: 10%;
  width: 100vw;
  background-color: #b4e2e6;
  font-size: 36px;
  top: 1000px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/*THIRD COONTAINER*/
/*info container holds two sponsors containers*/

.info-container {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100vw;
  background-color: white;
  font-size: 36px;
  top: 1000px;
  align-content: center;
  text-align: center;
  border-left-width: 10px;
  border-left-style: solid white;
  border-right-style: solid white;
  border-right-width: 10px;
}

.bci-info {
  align-items: center;
  padding-top: 1.5vh;
  padding-bottom: 2vh;
  background-color: rgb(190, 190, 190);
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.sponsors-info {
  padding-top: 1.5vh;
  padding-bottom: 2vh;
  background-color: white;
  width: 90%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-left: 4vh;
  padding-right: 4vh;
  text-align: center;
}

.sponsorButtons {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.bci-column {
  /* Create two equal columns that floats next to each other */
  display: grid;
  width: 50%;
  align-items: center;
  text-align: center !important;
}

.bci-column.bci-text {
  background-color: white;
  padding-right: 15px;
  padding-left: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 20vw;
}

.bci-row {
  content: "";
  display: flex;
  clear: both;
  align-items: center;
  background-color: white;
}

.open-bci-product, .branch-out-image, .jacqueline-image{
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  min-width: 30vh;
  min-height: 20vw;
  background-image: url(/static/media/open-bci-product.452583b4.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: unset;
  background-color: white;

}

.branch-out-image{
  background-image: url(/static/media/branchout_pic.29a05990.png);
}

.jacqueline-image{
  background-image: url(/static/media/jcummine.045889bc.jpg);
}



/*FOURTH COONTAINER*/
/*another option for where to put sponsors*/

.sponsors-container {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: rgb(235, 235, 235);
  font-size: 36px;
  top: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

/*FIFTH COONTAINER*/
/*will most likely contain information OR picture about the unity game,
and baseline*/

.video-container {
    height: 100vw;
    background-color: blue;
    font-size: 36px;
    top: 1000px;
}

/*FIFTH COONTAINER*/

.notes-on-game-container {
    height: 100vw;
    background-color: black;
    font-size: 36px;
    top: 2000px;
}

/*below are the bacground elements, each hold a png
that is place in absolute position*/

.neuron {
  position: absolute;
  width: 174px;
  height: 373px;
  left: 3%;
  top: 15%;
  background-image: url(/static/media/neuron.12c65278.png);
  background-attachment: local;
  background-size: contain;
  z-index: 1;
}

.neuron2 {
  position: absolute;
  width: 269px;
  height: 581px;
  left: 6%;
  top: 60%;
  background-image: url(/static/media/neuron.12c65278.png);
  background-attachment: local;
  background-size: contain;
  z-index: 1;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  display:none;
}


.tiles {
  position: absolute;
  width: 306px;
  height: 167px;
  left: 45%;
  top: 12%;
  background-image: url(/static/media/cards.3cd93a6f.png);
  background-attachment: local;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
}

.foldercard {
  position: absolute;
  width: 207px;
  height: 185px;
  left: 85%;
  top: 67%;
  background-image: url(/static/media/card-folder.6d5d63f0.png);
  background-attachment: local;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
}

/*the media queries below maintain legibility for large and small screen sizes*/

@media only screen and (max-width: 1100px) {
  .home2-mainContent {
    flex-direction: column;
  }
}

@media only screen and (max-width: 900px) {
  .neuron {
    left: 1%;
    top: 15%;
    width: 131px;
    height: 281px;;
  }
}

@media only screen and (max-width: 1300px) {
  .neuron2 {
    display: none;
  }
}

@media only screen and (min-width: 1100px) {
  .neuron {
    width: 269px;
    height: 581px;
    left: calc(30% - 520px);
    top: 25%;
  }
  .tiles {
    width: 385px;
    height: 210px;
  }
  .foldercard {
    width: 260px;
    height: 232px
  }
  .home2-h4 {
    font-size: 16pt;
  }
}

@media only screen and (max-width: 1100px) {
  .tiles {
    left: 10%;
    top: 60%;
  }
  .foldercard {
    left: 70%;
    top: 75%;
  }
  .background-image {
    background-image: url(/static/media/test_background2.b64e7cd0.png);
    width: 100vw;
  }
  .bullet-points {
    display: none;
  }
  .title-container {
    padding: 80px 160px 30px 60px;
  }

}
@media only screen and (max-width: 900px) {
  .tiles {
    left: 10%;
    top: 60%;
    width: 211px;
    height: 115px;
  }
  .foldercard {
    left: 70%;
    top: 80%;
    width: 211px;
    height: 115px;
  }
  .home2-h1 {
    font-size: 30pt;
  }
  .fancy-digit {
    font-size: 25px;
  } 
  .title-container {
    padding: 80px 40px 30px;
    margin-right: 0px;
    padding-top: 10%;
  }
  .bci-row {
    justify-content: center;
  }
  .bci-column {
    min-height: 40vw;
  }
  .open-bci-product {
    min-height: 60vw;
  }
  .SponsorButton {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  
}

@media only screen and (max-width: 400px) {
  /* .title-container {
    padding: 0 0 0 0;
    margin-right: 0px;
    padding-top: 30px;
  } */
  .tiles {
    display: none;
  }
  .Cards {
    padding: 0;
  }
  .neuron {
    display: none;
  }
  .title-container {
    padding-top: 20%;
    padding-right: 20%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .bci-column.bci-image {
    display: none;
  }
}

/*below are other componenent that contribute to the overall
layout outlined in the css above*/

.learn-more-link {
  color: #48B4BD;
  font-size: 2vh;
}

.bullet {
  max-height: 1em;
  max-width: 1em;
  padding-right: 0.5em;
  margin-bottom: -0.3em;
}

.fancy-digit{
  height: 2em;
  width: 2em;
  border-radius: 50%;
  color: white;
  vertical-align: middle;
  display: table-cell;
  background-color: #48B4BD;
  border: 5px solid white;
}

.character {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 300px;
  width: 300px;
  background-color: #e37158;
  border-radius: 20px;
}

.home-card {
  height: 7em;
  width: 30vw;
  color: #48B4BD;
  background-color: white;
  border: solid #48B4BD;
  border-radius: 20px;
  margin: 0.5vw;
  /* margin-bottom: 1vw; */
}

.card-container {
  display: inline-block;
}

.home-stats-card {
  font-size: 1vw !important;
  margin: 1rem auto;
  height: 12vh;
  width: 20vw;
  min-width: 100%;
  color: #48b4bd !important;
  box-shadow: none !important;
}

.home-stats-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 3vw;
  gap: 3vw;
}

.home-stats-text {
  font-family: 'Righteous', cursive;
  font-size: 3vh;
  font-weight: 200;
}

.home-stats-number {
  color: #000000;
  font-size: xx-large;
}


/*Big screens*/
@media screen and (min-width:1280px)  {
  .cardGrid{
    display: inline-block;
  }
}

/*Small screens*/

@media screen and (max-width:900px){
.cardGrid {
  margin: 0 auto !important;
  }
}

/*Big screens*/
@media screen and (min-width: 1280px) {
  .cardGrid {
    display: inline-block;
  }
  .home-card {
    margin: 1rem;
  }
  .home-stats-card {
    margin: 1rem;
  }

}

/* Medium Screens */
@media screen and (max-width: 900px) {
  .home-stats-card {
    width: 50vw;
  }
}

/*Small screens*/
@media screen and (max-width: 500px) {
  .home-stats-card {
    height: 100%;
  }

  .home-stats-flex {
    flex-direction: column;
  }

}

a {
    color:#e37158;
}
.header-sizer {
    height: 80px;
    /* overflow: hidden; */
}

.header {
    background-color: #48b4bd;
    position: fixed;
    vertical-align: middle;
    display: inline-block;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    justify-content: space-between;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
}

.drop-shadow {
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}

.floating {
    position:fixed;
    top:0;
    z-index: 100;
}

.navBarLink {
    /*The color was overriden by the imported component*/
    /*hey let's avoid using !important, it tends to overlook other problems in the code,
    if you run into these kinds of problems, try fixing how specific the code is and it should work better
    that is, use multiple classes as target so that the css doesn't spread to other pages
    e.g. instead of .a maybe do .a.buttonPretty etc. this is targetting a very specific tag not all <a> tags*/
    color: white;
    display:inline-block;
    margin:0;
    text-transform:uppercase;
    font-size: 20px;
    margin-right: 1.5rem;
    text-decoration: none;
    padding-bottom: 5px;
}
.navBarLink:after {
    display:block;
    content: '';
    border-bottom: solid 3px white;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    transition: 250ms ease-in-out;
}
.navBarLink:hover:after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
}

.navBarLink:active:after {
    color: #e3b757;
    border-bottom: none;
}
.navBarLink:active {
    color: #e3b757;
    border-bottom: solid 3px #e3b757;
    -webkit-transform: none;
            transform: none;
}
.unfancy-link.home-link {
    text-decoration: none!important;
}

.unfancy-link.home-link:after {
    display:block;
    content: '';
    border-bottom: solid 3px black;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    transition: 250ms ease-in-out;
}
.unfancy-link.home-link:hover:after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
}

.unfancy-link.home-link:active:after {
    color: white;
    border-bottom: none;
}
.unfancy-link.home-link:active {
    color: white;
    border-bottom: solid 3px white;
    -webkit-transform: none;
            transform: none;
}

 @media only screen and (max-width: 500px) {
    .logo {
        display: none;
    }
 }
img.profile-img {
    padding: 5px;
    height: 2.5rem;
    border-radius: 10000px;
    align-self: center;
    text-align: center;
}

div#profile-img-div {
    align-items: center;
}

img.profile-img:hover {
    background-color: rgba(0,0,0,0.1);
    transition: background 250ms ease-in;
}
#logo {
    height: 70%;
}
/* 
#circleProgress {
    height: 10rem;
} */

#loadingText {
    font-family: 'Righteous', cursive;
}
/* img.profile-img {
    padding: 5px;
    height: 2.5rem;
    border-radius: 10000px;
    align-self: center;
    text-align: center;
} */

div#profile-img-div {
 align-items: center;
}

img.profile-img:hover {
    background-color: rgba(0,0,0,0.1);
}
/* buton class characteristics*/
.prettyButton{
    color: #48B4BD;
    font-size: calc(10px + 2vmin);
    font-family: 'Righteous', cursive;
    border-width: medium;
    border-radius: 20px;
    border-color: #48B4BD;
    background-color: white;
    margin: auto;
}
/*buton class when selected*/
.prettyButton:hover{
    background-color: #b4e9ed
}

/*link style*/

a.prettyButton {
    color:	#276268;
    font-family: 'Righteous', cursive;
    text-decoration: none;
}
/*selected link*/
a.prettyButton:active {
    color: black;
}
/*visited link*/
a.prettyButton:visited {
    color:#48B4BD;
}



/* buton class characteristics*/
.LogInButton{
    color:  white;
    font-size: calc(10px + 2vmin);
    font-family: 'Righteous', cursive;
    border-width: medium;
    border-radius: 20px;
    border-color: white;
    background-color: #e3b757;
    margin: auto;
    margin-right: 10px;
    margin-left: 10px;
}
/*buton class when selected*/
.LogInButton:hover{
    background-color: #f3daa5;
}

/*link style*/

a.LogInButton {
    color:	white;
    font-family: 'Righteous', cursive;
    text-decoration: none;
}
/*selected link*/
a.LogInButton:active {
    color: black;
}
/*visited link*/
/* a.LogInButton:visited {
    color:#a5e6a7;
} */



/* buton class characteristics*/
.SponsorButton{
    /* color:  white;
    font-size: calc(10px + 2vmin);
    font-family: 'Righteous', cursive;
    border-width: medium;
    border-radius: 20px;
    border-color: white;
    background-color: #e3b757;
    margin: auto;
    margin-right: 10px;
    margin-left: 10px;
    margin: 1rem auto; */
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: #48b4bd !important;
    background-color: white !important;
    border-width: medium !important;
    border-radius: 20px !important;
    border-color: #48b4bd !important;
    margin: 15px;
}

.div-for-sponsor-buttons {
    display:inline-block;
    align-items: center;
}
  
.home-sponsor-image {
    min-height: 3vh;
    min-width: 6vh;
    width: 44vh;
}
  
/*buton class when selected*/
.SponsorButton:hover{
    background-color: #b4e9ed
}

/*link style*/

a.SponsorButton {
    color:	#276268;
    font-family: 'Righteous', cursive;
    text-decoration: none;
}
/*selected link*/
a.SponsorButton:active {
    color: black;
}
/*visited link*/
a.SponsorButton:visited {
    color:#48B4BD;
}



/* HomeCard properties */

.sponsor-row {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.card-background {
    background: #FFFFFF;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    min-height: 30vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 48%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 10px;
    /* margin-right: 50px; */
}

.image-background-bci, .image-background-branch-out, .image-background-uofa {
    position: relative;
    height: auto;
    width: 50vw;
    left: 0px;
    top: 0px;
    bottom: 0px;
    background: #BCD1FF;
    border-radius: 16px 0px 0px 16px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: unset;
}

.image-background-bci {
    background-image: url(/static/media/open-bci-product.452583b4.png);
}

.image-background-branch-out{
    background-image: url(/static/media/branchout_pic.29a05990.png);
  }

.image-background-uofa {
    background-image: url(/static/media/uofa-image.2664574d.png);  
}


.container-text {
    padding: 20px;
    text-align: left;
}
.container-button {
    padding: 20px;
    text-align: center;
}
.sponsor-title {
    position: relative;
    font-family: 'Righteous', cursive;
    font-size: 18pt;
    font-weight: 100;
    line-height: 27px;
    /* Additional text */
    color: #6E798C;
}
.sponsor-p {
    position: relative;
    top: 10%;
    width: 100%;
    /* Open Sans / 18 sp • Body 1 */
    font-family: 'Roboto', sans-serif;
    font-size: 12pt;
    font-weight: 500;
    line-height: 27px;
    /* or 150% */
    /* Body text */
    color: #374A59;
    text-align: left;
    margin-bottom: 40px;
}

@media only screen and (max-width: 1200px) {
    .image-background-bci, .image-background-branch-out, .image-background-uofa {
    display: none;
  }
}
/* .Cards{
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  padding: 2vw;
  position: relative;
  width: 70%;
  padding-left: 15%; 
  padding-right: 15%;
} */
.card-container {
  display: inline-block;
}

.home-stats-card {
  font-size: 1vw !important;
  margin: 1rem auto;
  height: 12vh;
  width: 20vw;
  min-width: 100%;
  color: #48b4bd !important;
  box-shadow: none !important;
}

.home-stats-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-stats-text {
  font-family: 'Righteous', cursive;
  font-size: 3vh;
  font-weight: 200;
}

.home-stats-number {
  color: #000000;
  font-size: xx-large;
}

.home-sponsor-card {
  margin: 1rem auto;
  min-width: 90%;
  width: 30vw;
  color: #48b4bd !important;
  background-color: white !important;
  border-width: medium !important;
  border-radius: 20px !important;
  border-color: #48b4bd !important;
}

.home-sponsor-image {
  min-height: 10vh;
  min-width: 90%;
}
  /* .cardGrid{
    display: inline-block;
  } */


@media screen and (min-width:1280px)  {
  .cardGrid{
    display: inline-block;
  }
}


@media screen and (max-width:900px){
.cardGrid {
  margin: 0 auto !important;
  }
}

@media screen and (min-width: 1280px) {
  .cardGrid {
    display: inline-block;
  }
  /* .home-stats-card {
    margin: 1rem;
  }
  .home-sponsor-card {
    margin: 1rem;
  } */
}


@media screen and (max-width: 900px) {
  .home-stats-card {
    width: 50vw;
  }
}


@media screen and (max-width: 500px) {
  .home-stats-card {
    height: 100%;
  }

  .home-stats-flex {
    flex-direction: column;
  }
}


@media screen and (max-width: 300px) {
  .home-stats-flex {
    flex-direction: column;
  }
}
/* buton class characteristics*/
.prettyButton{
    color: #48B4BD;
    font-size: calc(10px + 2vmin);
    font-family: 'Righteous', cursive;
    border-width: medium;
    border-radius: 20px;
    border-color: #48B4BD;
    background-color: white;
    margin: auto
}
/*buton class when selected*/
.prettyButton:hover{
    background-color: #b4e9ed
}

/*link style*/

a.prettyButton {
    color:	#276268;
    font-family: 'Righteous', cursive;
    text-decoration: none;
}
/*selected link*/
a.prettyButton:active {
    color: black;
}
/*visited link*/
a.prettyButton:visited {
    color:#48B4BD;
}


/* // BUTTON PROPERTIES // */
.footer {
    left: 0;
    right: 0;
    /* bottom: 0; */
    width: 100%;
    text-align: center;
    position: absolute;
    color: white;
    overflow: hidden;
    display: inline;
    z-index: 999;
}

/* social media button class */
.social-media-button {
    padding: 10px;
    border-radius: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 40%;
    max-height: 40%;
}
/* social media button specific shape*/
.social-media-button path {
    fill: #48b3bd;
    max-height: 50px;
}

/* clicked social media button */
.social-media-button path:active {
    fill: white;
    max-height: 50px;
}

/* selected social media button*/
.social-media-button:hover {
    border-radius: 50%;
    background-color: #9cd1d5;
    transition: 250ms ease-in;
} 

/* clicked social media button */
.social-media-button:active {
    background-color: #9cd1d5;
}

/* // FOOTER PROPERTIES//  */

.footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    padding-top: 10px;
}

.text {
    margin-left: 10px;
    color: #276268;
}

.icons-container {
    display: flex;
    align-items: center;
}
.footer-line {
    background-color: #48b3bd;
    position: relative;
    left: 0;
    bottom: 0;
    height: 15px;
    width: 100%;
    z-index:1000;
    margin-top: 10vw;
}

/*Samll screens...*/
@media screen and (max-width: 1000px) {
    .footer {
        position: fixed;
        bottom: 0;
    }
}
.headerTitle {
  font-family: 'Righteous', cursive;
  text-align: center;
  position: inherit;
}
.title-container {
  display: flex;
  flex-direction: column;
 align-items:center;
  margin: 5%;
}

.info-title {
  font-size: 3rem;
}

.buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

.button {
  background-color: rgba(255,255,255,0);
  color: black;
  width: 20rem;
  height: 4rem;
  font-size: 1.5rem;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  border-radius: 20px;
  border: solid;
  font-family: 'Righteous', cursive;
}
.button:hover {
  background-color: rgba(0,0,0,0.2);
  -webkit-transform: scale(1.05);
          transform: scale(1.05)
}
.button:active {
  backround-color: red;
  -webkit-transform: scale(1);
          transform: scale(1)
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

.redSquare {
  height: 100px;
  width: 100px;
  background-color: red;
}

.greenSquare {
  height: 100px;
  width: 100px;
  background-color: green;
}
.openbciBody {
  background-image: url(/static/media/background_top.43d1a4dc.png);
  background-size: 100%;
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
}

.setup-openbci {
  padding: 10px;
  margin: 10px;
}

.openbci-selection {
  padding: 10px;
  width: 100%;
  background-color: transparent;
  font-size: x-large;
}

.error-message {
  color: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.intermediate-session-main-container {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
}

.tutorial-pics{
    height: 15rem;
    width: 30rem;
}

.verify{
    font-size: 2rem;
    padding-top: 3rem;
}

.Check{
    font-size: 30px;
    text-align: center;
    line-height: 50px;
    width: 50px;
    height: 50px;
}

.dot{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #48B4BD;
    display: inline-block;
    margin: 0 1rem;
}


.tinyBody{
    padding: 4rem;
}



#false{
    background-color: #e37158;
}

.numberDot{
    background-color: #e37158;
}

.numbers{

    text-align:center;
    line-height:50px;
    width:50px;
    height:50px;
    font-size:1.5rem;
}

.numberCard{
    padding:1rem!important;
    border-color: #48B4BD;
    border-width: .25em;
    border-radius: 0.5em;
    border-style: solid;
    margin: 1em;
}
.title-container {
  display: flex;
  flex-direction: column;
 align-items:center;
  margin: 5%;
}

.info-title {
  font-size: 3rem;
}

.word-card {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  height:10rem;
  width:10rem;
  background-color: #E37158;
  border-radius: 20px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.wordsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row;
}

.buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

.equalText {
  font-family: 'Righteous', cursive;
  font-size: 5rem;
  color: rgb(100,100,100);
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#button-spacer {
  width: 2rem;
}

.buttonEquate {
  background-color: rgba(255,255,255,0);
  color: black;
  width: 20rem;
  height: 4rem;
  font-size: 1.5rem;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  border-radius: 20px;
  border: solid;
  font-family: 'Righteous', cursive;
}
.buttonEquate:hover {
  background-color: rgba(0,0,0,0.2);
  -webkit-transform: scale(1.05);
          transform: scale(1.05)
}
.buttonEquate:active {
  -webkit-transform: scale(1);
          transform: scale(1)
}
.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5%;
}

.info-title {
  font-size: 3rem;
}

.word-card {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  height: 10rem;
  width: 10rem;
  background-color: #e37158;
  border-radius: 20px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.wordsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row;
}

.buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

.equalText {
  font-family: 'Righteous', cursive;
  font-size: 5rem;
  color: rgb(100, 100, 100);
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#button-spacer {
  width: 2rem;
}

.buttonEquate {
  background-color: rgba(255, 255, 255, 0);
  color: black;
  width: 20rem;
  height: 4rem;
  font-size: 1.5rem;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  border-radius: 20px;
  border: solid;
  font-family: 'Righteous', cursive;
}
.buttonEquate:hover {
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.buttonEquate:active {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.sessionCard {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 5px;

  height: auto;
  background-color: rgb(248, 248, 248);
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

  font-size: 28px;
}

.keeptogether {
  white-space: nowrap;
}

kbd {
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #b4b4b4;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
  color: #333;
  display: inline-block;
  font-size: 0.85em;
  font-weight: 700;
  line-height: 1;
  padding: 2px 4px;
  white-space: nowrap;
}

.sessionHighlight {
  color: #e3b757;
}

.sessionNumber {
  color: #48b4bd;
}

@media (max-width: 768px) {
  .sessionCard {
    min-width: 370px;
    width: 90%;

    padding: 20px 10px;
  }
}

@media (min-width: 768px) {
  .sessionCard {
    width: 720px;

    padding: 20px 25px;
  }
}

.verify-email {
    padding-top: 1rem;
}

.profile-img-large {
    width: 5rem;
    border-radius: 10000px;
}

div.profile-container {
    align-self: center;
    text-align: center;
    padding: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
    background-color: rgba(0,0,0,0.1);
    
}

.profile-data-header {
    font-size: 1.3rem;
    margin-bottom: 0.3rem;
}

.display-name {
    font-size: 1.2rem;
}

.email-address {
    font-size: 0.8rem;
}

.profile-data-card-large {
    margin: 0.3rem;
    min-width: 500px; 
    /* max-width: 500px */
}

.profilepage-maincontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
.profile-data-card {
    display: flex;
    align-content: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    background-color: white;
    border-radius: 5px;
    margin: 5px;
    padding: 10px;
    height: 100%;
    flex-direction: column;
    /* max-width: 570px; */
    /* max-width: 60vw */
}

.profile-performance-card {
    display: flex;
    justify-content: center;
    width: 1200px;
    max-width: 1200px;
}

.profile-small-card-container {
    display: flex;
    justify-content: center;
    align-content: space-evenly;
}

.profile-data-card-small {
    width: 100%;
}
.padding {
    margin: 0.3rem;
    /* min-width: 0px;
    max-width: 50px; */
}


.profile-left-column {
    /* Create two equal columns that floats next to each other */
    display: grid;
    max-width: 600px;
    align-items: center;
    text-align: center !important;
  }

  .profile-right-column {
    /* Create two equal columns that floats next to each other */
    display: grid;
    max-width: 600px;
    align-items: center;
    text-align: center !important;
  }
  
  .profile-row {
    content: "";
    display: flex;
    clear: both;
    align-items: center;
    background-color: white;
    flex-wrap:wrap;
    justify-content: center;
  }

  /* .profile-row.big-screen.profile-data-card{
    max-width: 1180px;
} */



  .pie-data-card {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

/*Big screens*/
/* @media screen and (min-width: 1200px) {
    .profile-data-card {
        max-width: 1180px;
    }

  } */
.hint {
  color: rgba(0,0,0,0.6);
  font-size: 0.8rem;
}
.session-details {
  background-color: rgba(0,0,0,0.05);
  border-radius: 20px;
  padding: 10px;
}
.mediumcard {
  min-width: 500px;
  min-height: 200px;
}

.characterSmall {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height:50px;
  min-width: 30px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5px !important;
  background-color: #E37158;
  border-radius: 20px;
  

}
.characterMedium {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height:150px;
  width: 150px;
  background-color: #e3b758;
  border-radius: 20px;
  font-family: 'Righteous', cursive;
  margin: 10px 5px 0 0 !important;
}

/*This changed the pie chart text to black
why? idk ask google*/
g text
{
    fill: black;
}

g path
{
  stroke-width: 5;
}

@media only screen and (max-width: 600px) {
  .characterMedium {
    height:120px;
    width: 120px;
  }
}
h1 {
  font-family: 'Righteous', cursive;
  font-size: 6vh;
  margin-bottom: 0px;
}

h2 {
  font-family: 'Righteous', cursive;
  font-size: 4vh;
  margin-bottom: 0px;
}

p {
  font-family: 'Roboto', sans-serif;
  font-size: 3vh;
  font-weight: 200;
  text-align: center;
}

a{
  text-decoration: none;
  font-weight: 400;
  color: #E78974;
}

ul{
  list-style-type: none;
  padding: 1vh;
  margin:0;
}


li{
  padding: 0.2rem 0;
}


em {
  color: rgb(70, 70, 70);
  font-weight: 600;
  font-style: inherit;
}

hr {
   /*background: linear-gradient(to left, #48b4bd, white);*/
  height: 2px;
  border-width: 0;
}

.Info {
  padding-top: 65px;
  width: 100%;
  text-align: center;
  background-color: white;
  background-image: url(/static/media/background_top.43d1a4dc.png);
  background-size: 100%;
  background-repeat: no-repeat;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 80px 60px 100px 60px;
  grid-gap: 150px;
  gap: 150px;
  
}

.mainContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 70px;
  gap: 70px;
}

.cardMedia {
  margin: 0;
  max-width: 650px;
  max-height: 366px;
  background-color: transparent;
}

.steamCard {
  color: white !important;
  background-color: #48b4bd !important;
  width: 100%;
}

.steamText {
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 3vh;
  font-weight: 200;
}

.steamTitle {
  font-family: 'Righteous', cursive;
  font-size: 5vh;
}

.steamRequirements {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.steamTinyHeading {
  font-family: 'Righteous', cursive;
  font-size: 3vh;
  padding: 2vh 0 2vh 0;
  text-decoration: underline;
}



@media only screen and (max-width: 1100px) {
  .mainContent {
    flex-direction: column;
  }

  .cardMedia {
    max-width: 560px;
    height: 315px;
  }

  .steamRequirements {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 700px) {
  .content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .cardMedia {
    max-width: 400px;
    height: 225px;
  }
}

@media only screen and (max-width: 370px) {
  .steamText {
    font-size: 2vh;
  }

  .steamTitle {
    font-size: 2.8vh;
  }

  .steamTinyHeading {
    font-size: 2.5vh;
  }
}

.sm-padding {
    padding-top: 10px;
}

.lg-padding { 
    padding-top: 50px;
}
html, body {
    max-width: 100%;
    /* overflow-x: hidden; */
    /* background-image: url("/src/background_bot.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: bottom; */
    background-image: url(/static/media/background_top.43d1a4dc.png);
    background-size: 100%;
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
}
#team-page {
    min-height: 100vh;
}

.title {
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5px;
}

@media (min-width: 768px) {
    .team-container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .team-member {
        padding: 1rem;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        min-width: 250px;
        max-width: 250px;
    }

    .member-position{
        font-size: 1rem;
    }

    .member-name{
        font-size: 21px;
    }

    .meet{
        font-size: 2em;
    }

    .dev-mention {
        font-size: 20px;
    }

}

@media (max-width: 768px) {
    .meet{
        font-size: 1.7em;
    }

    .dev-mention {
        font-size: 18px;
    }

    .team-member {
        padding: 0.5rem;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        min-width: 200px;
        max-width: 200px;
    }

    .member-name{
        font-size: 17px;
    }

    .member-position{
        font-size: 14px;
    }
}

.team-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.team-member {
    display: flex;
    flex: 2 1 auto;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
}

.team-member:hover {
    background-color: rgb(240, 240, 240);
    cursor: pointer;
}

.active {
    background-color: rgb(240, 240, 240);
}

.member-photo {
    width: 9.5rem;
    height: 9.5rem;
    border-radius: 50%;
    object-fit: cover;
}

.member-name {
    margin-top: 8px;
    margin-bottom: 5px;
}

.member-info-expanded {
    width: 60%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: rgb(240, 240, 240);
    position: absolute;
    display: flex;
    box-sizing: border-box;
    padding: 2%;
    margin-left: 3%;
    margin-right: 3%;
    border-radius: 5px;
    box-shadow: rgb(190, 190, 190) 0px 2px 7px 0px;
    overflow: hidden;
    min-width: 90vw;
}

.hidden {
    position: absolute;
    left: -9999px;
    visibility: hidden;
}

.personal-info {
    margin-top: -0.5%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.linkedin-logo {
    margin-right: 5px;
}

.linkedin-link {
    padding: 1% 2% 1% 2%;
    margin-top: 1rem;
    width: -webkit-min-content;
    width: min-content;
    border-radius: 5px;
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
}

.linkedin-link:hover {
    background-color: #e4e4e4;
    cursor: pointer;
}

.member-name-expanded {
    font-size: 1.5rem;
    width: -webkit-max-content;
    width: max-content;
}

@media only screen and (max-width: 600px) {
    .member-info-expanded {
        flex-direction: column;
        width: -webkit-max-content;
        width: max-content;
        max-width: 90%;
    }

    .linkedin-link {
        margin-top: 3px;
        padding: 0;
    }

    .personal-info {
        margin-bottom: 20px;
    }
}

/* Mobile member card */
.mobile-member-card{
    margin: 15px;
}

.mobile-member-photo{
    width: 8.5rem;
    height: 8.5rem;
    border-radius: 50%;
    object-fit: cover;
}
.error {
    margin: auto;
    margin-top: 100px;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 30px 20px 30px;
    background-color: rgb(233, 111, 111);
    border-radius: 20px;

    font-size: larger;
}

.error h1 {
    font-size: 3rem;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: auto;
  /* display: flex; */
  /* justify-content: center; */
  /* overflow: hidden; */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.windowGame {
  padding-top: 10vh;
  padding-left: 10vh;
  padding-bottom: 10vh;
  padding-right: 10vh;
  max-height: 90%;
  min-width: 80%;
  display: inline-flex;
  overflow: hidden;
  /* justify-content: center; */
  /* width: 60%;
  border: 5px solid #FFFF00; */
  /* padding: 10px; */
  
}
.communityBody{
    background-image: url(/static/media/background_top.43d1a4dc.png);
    background-size: 100%;
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
}

h1{
    margin-top: 5%;
    margin-bottom: 5px;
    text-align: center;
}

.subtitle{
    font-size: 20px;
    padding-bottom: 5px;
}

.marginRight5{
    margin-right: 5px;
}

.marginBottom24{
    margin-bottom: 24px;
}

.paddingBottom5{
    padding-bottom: 5px;
}

.leaderboardCards{
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 768px){
    .leaderboardCards{
        min-width: 370px;
        width: 90%;
    }
    .profile-pic{
        margin-right: 10px;
        margin-left: 5px;
    }
    .cards-content{
        padding: 20px 10px;
    }
    .detail-stats{
        padding: 0 10px;
    }
    h1{
        font-size: 1.7em;
    }
}

@media (min-width: 768px){
    .leaderboardCards{
        width: 720px;
    }
    .profile-pic{
        margin-right: 25px;
        margin-left: 15px;
    }
    .cards-content{
        padding: 20px 25px;
    }
    .detail-stats{
        padding: 0 25px;
    }
    h1{
        font-size: 2em;
    }
}

@media(max-width: 450px){
    .viewDetails{
        display: none;
    }
}

.viewDetailsButton{
    color: black;
}

.viewDetailsButton:hover{
    text-decoration: underline;
    cursor:pointer;
}

.cards{
    margin-bottom: 5px;
}

.divider{
    height: 2px;
    background-color: black;
    margin-bottom: 5px;
}

.profile-card, .stats-card{
    height: auto;
    background-color: rgb(248, 248, 248);
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px 0 rgba(0,0, 0, 0.1), 0 1px 2px 0 rgba(0,0,0, 0.06);
}

.stats-card{
    margin-top: 10px;
    margin-bottom: 15px;
}

.cards:first-child .profile-card{
    background-color: #48b4bd;
}

.cards:nth-child(2) .profile-card{
    background-color: #E37158;
}

.cards:nth-child(3) .profile-card{
    background-color: #e3b757;
}

.rankingNum{
    font-size: 50px;
    min-width: 65px;
}

.profile-pic{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.profile-name{
    font-size: 28px;
    margin-bottom: 2px;
}

.sortedByButton{
    cursor: pointer;
}
