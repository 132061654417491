.openbciBody {
  background-image: url('/src/background_top.png');
  background-size: 100%;
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
}

.setup-openbci {
  padding: 10px;
  margin: 10px;
}

.openbci-selection {
  padding: 10px;
  width: 100%;
  background-color: transparent;
  font-size: x-large;
}

.error-message {
  color: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.intermediate-session-main-container {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
}
