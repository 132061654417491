body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: auto;
  /* display: flex; */
  /* justify-content: center; */
  /* overflow: hidden; */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.windowGame {
  padding-top: 10vh;
  padding-left: 10vh;
  padding-bottom: 10vh;
  padding-right: 10vh;
  max-height: 90%;
  min-width: 80%;
  display: inline-flex;
  overflow: hidden;
  /* justify-content: center; */
  /* width: 60%;
  border: 5px solid #FFFF00; */
  /* padding: 10px; */
  
}