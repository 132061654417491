.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5%;
}

.info-title {
  font-size: 3rem;
}

.word-card {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  height: 10rem;
  width: 10rem;
  background-color: #e37158;
  border-radius: 20px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.wordsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row;
}

.buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

.equalText {
  font-family: 'Righteous', cursive;
  font-size: 5rem;
  color: rgb(100, 100, 100);
  text-align: center;
  width: fit-content;
}

#button-spacer {
  width: 2rem;
}

.buttonEquate {
  background-color: rgba(255, 255, 255, 0);
  color: black;
  width: 20rem;
  height: 4rem;
  font-size: 1.5rem;
  transition: transform 0.2s;
  border-radius: 20px;
  border: solid;
  font-family: 'Righteous', cursive;
}
.buttonEquate:hover {
  background-color: rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}
.buttonEquate:active {
  transform: scale(1);
}

.sessionCard {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 5px;

  height: auto;
  background-color: rgb(248, 248, 248);
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

  font-size: 28px;
}

.keeptogether {
  white-space: nowrap;
}

kbd {
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #b4b4b4;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
  color: #333;
  display: inline-block;
  font-size: 0.85em;
  font-weight: 700;
  line-height: 1;
  padding: 2px 4px;
  white-space: nowrap;
}

.sessionHighlight {
  color: #e3b757;
}

.sessionNumber {
  color: #48b4bd;
}

@media (max-width: 768px) {
  .sessionCard {
    min-width: 370px;
    width: 90%;

    padding: 20px 10px;
  }
}

@media (min-width: 768px) {
  .sessionCard {
    width: 720px;

    padding: 20px 25px;
  }
}
