/* buton class characteristics*/
.prettyButton{
    color: #48B4BD;
    font-size: calc(10px + 2vmin);
    font-family: 'Righteous', cursive;
    border-width: medium;
    border-radius: 20px;
    border-color: #48B4BD;
    background-color: white;
    margin: auto
}
/*buton class when selected*/
.prettyButton:hover{
    background-color: #b4e9ed
}

/*link style*/

a.prettyButton {
    color:	#276268;
    font-family: 'Righteous', cursive;
    text-decoration: none;
}
/*selected link*/
a.prettyButton:active {
    color: black;
}
/*visited link*/
a.prettyButton:visited {
    color:#48B4BD;
}
